import React from 'react'
import { InfoCircleOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Avatar, Button, Popover, Tooltip } from 'antd'
import compopoLogo from './compopo-logo.jpg'
import styled, { css } from 'styled-components'
import productsApi from '../../api/products'
import { CURRENCIES } from '../../common/Constants'
import { isHebrew } from '../../common/utils'
import mixpanel from '../../common/mixpanel'
import { IReview } from '../../models/Review'
import SellerCard from './SellerCard'
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'
import { Retailer } from '../../models/OtherStore'
import { FaShippingFast } from 'react-icons/fa'

const IMAGE_SIZE = '180px';

const SHARE_TEXT = 'Compopo just found you an awesome product!'
interface Props {
  item: IReview
  currency?: string
}

const ProductCardWithReview = ({ item, currency }: Props) => {
  const { t } = useTranslation()
  const { image, title, itemUrl, price, retailer, seller, hasShippingInfo, country } = item
  const currencyCode = CURRENCIES.find((c) => c.value === currency)?.label
  const reviewedLogo = compopoLogo
  const reviewedBy = 'Reviewed by Compopo'
  // const titleRef = useRef<HTMLDivElement | null>(null)

  const handleAnalytics = () => {
    productsApi.goToProduct(item)
    mixpanel.track('Product click review', { item })
  }

  const handleShare = () => {
    const text = `${SHARE_TEXT} ${itemUrl} ${title}`
    window.open(`https://api.whatsapp.com://send?text=${encodeURIComponent(text)}`)
  }

  //const shortReview = item.review!.length > MAX_REVIEW_LENGTH ? item.review?.slice(0, MAX_REVIEW_LENGTH) + '...' : item.review
  const isRtl = isHebrew((item.review || '')[0])

  // const showTooltip = (titleRef.current?.offsetHeight || 0) < (titleRef.current?.scrollHeight || 0)

  return (
    <Root $retailer={retailer}>
      <Top>
        <Tooltip title={reviewedBy} placement="right">
          <Avatar size="large" src={reviewedLogo} />
        </Tooltip>
        <div>
          <ReactCountryFlag style={{ fontSize: '2em', lineHeight: 'inherit' }} svg countryCode={country} />
          <RetailerImg src={`/icons/${retailer}.svg`} alt={retailer} />
        </div>
      </Top>
      <Left>
        <ImageBox href={itemUrl} target="_blank" onClick={() => handleAnalytics()}>
          <img src={image.imageUrl} alt={title} loading="lazy" />
        </ImageBox>
        {/* <Tooltip title={showTooltip ? title : ''}>
          <Title ref={titleRef}>{title}</Title>
        </Tooltip> */}
      </Left>
      <Right $isRtl={isRtl}>
        <Title $isShort>{item.reviewTitle}</Title>
        <Review $isShort>{item.review}</Review>
        <Popover
          trigger={['click']}
          content={
            <FullReview>
              <Title>{title}</Title>
              <Review>{item.review}</Review>
            </FullReview>
          }
        >
          <Button type="link">{t('readMore')}</Button>
        </Popover>
      </Right>
      <Bottom>
        <Price>
          {currencyCode}
          {price.calcTotalPrice}
        </Price>
        <Button type="primary" href={itemUrl} target="_blank" onClick={handleAnalytics}>
          {t('buy')}
        </Button>
        <Actions>
          {hasShippingInfo && <FaShippingFast style={{ opacity: '0.7', fontSize: '18px', marginRight: '10px' }} />}
          <Button type="text" shape="circle" onClick={handleShare} icon={<ShareAltOutlined />} />
          <Popover trigger={['click']} placement="left" content={<SellerCard retailer={retailer} seller={seller} hasShipping={hasShippingInfo} title={title} />}>
            <Button type="text" shape="circle" icon={<InfoCircleOutlined />} />
          </Popover>
        </Actions>
      </Bottom>
    </Root>
  )
}

const amazonCSS = css`
  background: linear-gradient(0deg, rgba(254,189,105,0.02) 0%, rgba(229,190,140,0.12) 100%);
`

const ebayCSS = css`
  background: linear-gradient(0deg, rgba(54,101,243,0.02) 0%, rgba(92,124,218,0.12) 100%);
`

const aliCSS = css`
  background: linear-gradient(0deg, rgba(255,71,71,0.02) 0%, rgba(230,110,110,0.12) 100%);
`

const Root = styled.div<{$retailer: Retailer}>`
  background: #fff;
  transition: box-shadow 0.3s;
  box-shadow: 0 3px 5px rgb(0 0 0 / 20%);
  height: fit-content;
  display: grid;
  grid-template-areas:
    'top top'
    'left right'
    'bottom bottom';
  gap: 10px;
  &:hover {
    box-shadow: 0 3px 5px 3px rgb(0 0 0 / 20%);
  }
  ${props => props.$retailer === 'eBay' && ebayCSS}
  ${props => props.$retailer === 'amazon' && amazonCSS}
  ${props => props.$retailer === 'AliExpress' && aliCSS}
  @media screen and (max-width: 650px) {
    max-width: inherit;
    width: 100%;
    flex-direction: column;
    grid-template-areas:
      'top'
      'left'
      'right'
      'bottom';
  }
`

const Top = styled.div`
  grid-area: top;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`

const Bottom = styled.div`
  grid-area: bottom;
  display: flex;
  justify-content: flex-start;
  padding: 8px 15px;
  align-items: center;
  gap: 20px;
`

const Title = styled.div<{$isShort?: boolean}>`
  color: #3d3d3d;
  font-weight: bold;
  margin: 10px;
  box-sizing: content-box;
  ${props => props.$isShort && css`  
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `}
`

const ImageBox = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${IMAGE_SIZE};
  max-height: ${IMAGE_SIZE};
  width: ${IMAGE_SIZE};
  background-color: #fcfcfc;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
`

const Left = styled.div`
  width: ${IMAGE_SIZE};
  grid-area: left;
  @media screen and (max-width: 650px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const Right = styled.div<{$isRtl?: boolean}>`
  padding: 15px;
  width: 350px;
  grid-area: right;
  @media screen and (max-width: 650px) {
    max-width: inherit;
    width: 100%;
    flex-direction: column;
  }
  ${props => props.$isRtl && css`
    direction: rtl;
  `}
`

const Review = styled.q<{$isShort?: boolean}>`
  font-style: italic;
  ${props => props.$isShort && css`  
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `}
  &::before,
  &::after {
    font-family: u2000;
    font-size: 18px;
    font-style: normal;
  }
`

const Price = styled.div`
  font-weight: bold;
  color: #8c9490;
`

const FullReview = styled.div`
  width: 600px;
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`

const Actions = styled.div`
  display: flex;
  gap: 3px;
  margin-left: auto;
`

const RetailerImg = styled.img`
  width: 50px;
  align-self: flex-end;
  margin-top: -5px;
`

export default ProductCardWithReview
