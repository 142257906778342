import { BackTop, Empty, Result, Spin } from 'antd'
import { AxiosError } from 'axios'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { isMobile } from '../common/utils'
import Banners from '../components/Banners/Banners'
import CookieAccepts from '../components/CookieAccept/CookieAccepts'
import Explanations from '../components/Explanations/Explanations'
import Header from '../components/Header'
import ProductsGrid from '../components/ProductsGrid'
import RecommendedItems from '../components/RecommendedItems/RecommendedItems'
import SearchInputConnected from '../components/SearchInput'
import useLoadMore from '../hooks/useLoadMore'
import useProductsQuery, { useProductReviewsQuery } from '../hooks/useProductsQuery'
import useSettings from '../hooks/useSettings'
import { IComparedItem } from '../models/ComparedItem'
import { IReview } from '../models/Review'
import EPNDisclosure from '../components/EPNDisclosure/EPNDisclosure'

const HEBREW_ERROR = 'Hebrew chars are not supported'

const Home = () => {
  const [searchTerm, setSearchTerm] = useSearchParams({})
  const searchTermValue = !!searchTerm.get('q') ? searchTerm.get('q')! : undefined
  const { selectedCountry, selectedCurrency, saveSelectedCountry, saveSelectedCurrency } = useSettings()
  const orderBy = searchTerm.get('sort') || 'compopo-sort'

  const { data: products, isError, error, isLoading, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } = useProductsQuery(selectedCountry, selectedCurrency, searchTermValue)
  const { data: reviews, isLoading: reviewsLoading } = useProductReviewsQuery(selectedCountry, selectedCurrency, searchTermValue)
  useLoadMore(hasNextPage ?? false, isFetchingNextPage, fetchNextPage)

  const items = products?.pages.flatMap((p) => prepareData(p.comparedItems || [], selectedCurrency, orderBy))

  const handleSearch = (value: string) => {
    if (value === searchTermValue) {
      refetch()
      // amazonRefetch()
    }
    if (value) {
      searchTerm.set('q', value)
    } else {
      searchTerm.delete('q')
    }
    setSearchTerm(searchTerm)
  }

  const handleChangeOrdrer = (sort: string) => {
    searchTerm.set('sort', sort)
    setSearchTerm(searchTerm)
  }

  const isEmpty = getIsEmpty(items || [], isLoading)

  const isMobileMain = isMobile() && !searchTermValue

  return (
    <Root>
      <BackTop />
      <Header country={selectedCountry} isMobileMain={isMobileMain} currency={selectedCurrency} orderBy={orderBy} onChangeCountry={saveSelectedCountry} onChangeCurrency={saveSelectedCurrency} onChangeOrder={handleChangeOrdrer}>
        <SearchInputConnected defaultValue={searchTermValue} onSearch={handleSearch} />
      </Header>
      {!searchTermValue && (
        <TopWrapper>
          <Banners />
          <ExplWrapper $isMobileMain={isMobileMain}>
            <Explanations />
          </ExplWrapper>
        </TopWrapper>
      )}
      {(isEmpty || isError) && (
        <EmptyWrapper>
          {isError ? <Result status="warning" title={getErrorMessage(error as AxiosError)} />: <Empty description="No results found. Please refine your search" />}
        </EmptyWrapper>
      )}
      {!isEmpty && (
        <ProductsWrapper>
          {!searchTermValue && <RecommendedItems items={getItems(mapReviewPrices(reviews?.reviews || [], selectedCurrency) as any as IComparedItem[], isMobileMain)} isLoading={reviewsLoading} currency={selectedCurrency} />}
          <ProductsGrid items={getItems(items || [], isMobileMain)} isLoading={isLoading} currency={selectedCurrency} />
          {isFetchingNextPage && (
            <LoadMorePlaceholder>
              <Spin tip="Loading more..." />
            </LoadMorePlaceholder>
          )}
        </ProductsWrapper>
      )}
      <CookieAccepts />
      <EPNDisclosure />
    </Root>
  )
}

const getItems = (items: IComparedItem[], isMobileMainScreen: boolean) => {
  return isMobileMainScreen ? items.slice(0, 30) : items
}

const getErrorMessage = (error: AxiosError) => {
  if (error.response?.data === HEBREW_ERROR) {
    return 'עברית לא נתמך כרגע... אנא חפש באנגלית'
  }

  return 'Sorry, but something went wrong... 🥺 Please try again in a few seconds.'
}

const getIsEmpty = (items: any[], isLoading: boolean) => {
  return !isLoading && items.length === 0
}

const prepareData = (items: IComparedItem[], currency: string, orderBy: string) => {
  const updatedItems = mapPrices(items, currency)
  return sortItems(updatedItems, orderBy)
}

const mapPrices = (items: IComparedItem[], currency: string): IComparedItem[] => {
  return items.map(item => {
    item.otherStores.forEach(store => {
      if (store.price.extra[currency]) {
        store.price.calcTotalPrice = store.price.extra[currency].calcTotalPrice
      }
    })
    return item
  })
}

const mapReviewPrices = (items: IReview[], currency: string) => {
  return items.map(item => {
    if (item.price.extra[currency]) {
      item.price.calcTotalPrice = item.price.extra[currency].calcTotalPrice
    }
    return item
  })
}

const sortItems = (items: IComparedItem[], orderBy: string): IComparedItem[] => {
  if (orderBy === 'compopo-sort') {
    return items
  }
  const direction = orderBy === 'lowest-first' ? -1 : 1
  return [...items].sort((a, b) => {
    const aPrice = parseFloat(a.otherStores[0].price.calcTotalPrice)
    const bPrice = parseFloat(b.otherStores[0].price.calcTotalPrice)
    if (aPrice < bPrice) {
      return direction
    }
    if (aPrice > bPrice) {
      return direction * -1
    }
    return 0
  })
}

const Root = styled.div`
  background-color: #f8f8f8;
  min-height: 100vh;
`

const ProductsWrapper = styled.div`
  margin: auto;
  padding: 0 20px;
  max-width: 1600px;
`

const TopWrapper = styled.div`
  margin: auto;
  max-width: 1560px;
`

const LoadMorePlaceholder = styled.div`
  text-align: center;
  padding: 20px 0;
`

const EmptyWrapper = styled.div`
  padding: 100px 0;
`

const ExplWrapper = styled.div<{ $isMobileMain: boolean }>`
  ${(props) =>
    props.$isMobileMain &&
    css`
      transform: translateY(-138px);
      height: 0;
    `}
`

export default Home
