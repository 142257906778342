import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import ReactCountryFlag from 'react-country-flag'
import { IComparedItem } from '../../models/ComparedItem'
import { FaShippingFast } from 'react-icons/fa'
import { Button, Popover, Tooltip } from 'antd'
import { InfoCircleOutlined, ShareAltOutlined } from '@ant-design/icons'
import SellerCard from './SellerCard'
import { IOtherStore } from '../../models/OtherStore'
import { CURRENCIES } from '../../common/Constants'
import mixpanel from '../../common/mixpanel'
import productsApi from '../../api/products'

const SHARE_TEXT = 'Compopo just found you an awesome product!'

interface Props {
  item: IComparedItem
  currency?: string
  short?: boolean
}

const ProductCard = ({ item, currency, short = false }: Props) => {
  const titleRef = useRef<HTMLDivElement | null>(null)
  const { image, title, itemUrl } = item.otherStores[0]
  const currencyCode = CURRENCIES.find((c) => c.value === currency)?.label

  const handleShare = ({ itemUrl, title }: IOtherStore) => {
    const text = `${SHARE_TEXT} ${itemUrl} ${title}`
    window.open(`https://api.whatsapp.com://send?text=${encodeURIComponent(text)}`)
  }

  const handleAnalytics = (store?: IOtherStore) => {
    productsApi.goToProduct(store || item.otherStores[0])
    mixpanel.track('Product click', { store: store || item.otherStores[0]})
  }

  const showTooltip = (titleRef.current?.offsetHeight || 0) < (titleRef.current?.scrollHeight || 0)


  return (
    <Root $short={short}>
      <ImageBox href={itemUrl} target="_blank" onClick={() => handleAnalytics()}>
        <img src={image.imageUrl} alt={title} loading="lazy" />
      </ImageBox>
      <Tooltip title={showTooltip ? title : ''}>
        <Title ref={titleRef} $short={short}>
          {title}
        </Title>
      </Tooltip>
      <List>
        {item.otherStores.map((store, i) => (
          <ListItem key={`${store.id}_${i}`}>
            <Link href={store.itemUrl} target="_blank" onClick={() => handleAnalytics(store)}>
              <ReactCountryFlag style={{ fontSize: '2em', lineHeight: 'inherit' }} svg countryCode={store.country} />
              {short ? (
                <Place />
              ) : (
                <>
                  <Pipe />
                  <Retailer src={`/icons/${store.retailer}.svg`} alt={store.retailer} />
                </>
              )}
              <Price>
                {currencyCode}
                {store.price.calcTotalPrice}
              </Price>
            </Link>
            <Icons>
              {store.hasShippingInfo && <FaShippingFast style={{ opacity: '0.7', fontSize: '18px', marginRight: '10px' }} />}
              <Button type="text" shape="circle" onClick={() => handleShare(store)} icon={<ShareAltOutlined />} />
              <Popover trigger={['click']} placement="left" content={<SellerCard retailer={store.retailer} seller={store.seller} hasShipping={store.hasShippingInfo} title={title} />}>
                <Button type="text" shape="circle" icon={<InfoCircleOutlined />} />
              </Popover>
            </Icons>
          </ListItem>
        ))}
      </List>
    </Root>
  )
}

const Root = styled.div<{ $short: boolean }>`
  background: #fff;
  transition: box-shadow 0.3s;
  box-shadow: 0 3px 5px rgb(0 0 0 / 20%);
  width: 290px;
  height: fit-content;
  &:hover {
    box-shadow: 0 3px 5px 3px rgb(0 0 0 / 20%);
  }
  @media screen and (max-width: 650px) {
    max-width: inherit;
    width: 100%;
    ${(props) =>
      props.$short &&
      css`
        width: 90%;
      `}
  }
`

const Title = styled.div<{ $short?: boolean }>`
  color: #3d3d3d;
  font-weight: bold;
  margin: 10px;
  overflow: hidden;
  box-sizing: content-box;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 66px;
`

const ImageBox = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 290px;
  max-height: 290px;
  background-color: #fcfcfc;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
`

const List = styled.div``

const ListItem = styled.div`
  display: flex;
  align-items: center;
  color: #3d3d3d;
  padding: 0 15px;
  transition: background-color 0.3s;
  &:first-child {
    background-color: #379cf210;
  }
  &:hover {
    background-color: rgba(61, 61, 61, 0.03);
  }
  border-top: solid 1px rgba(61, 61, 61, 0.05);
`

const Link = styled.a`
  display: flex;
  align-items: center;
  flex: 1;
`

const Icons = styled.div`
  width: 102px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Retailer = styled.img`
  width: 35px;
`

const Pipe = styled.div`
  margin: 0 8px;
  border-right: solid 1px rgba(61, 61, 61, 0.1);
  height: 20px;
`

const Price = styled.div`
  font-weight: bold;
  color: #8c9490;
  flex: 1;
  text-align: center;
`

const Place = styled.div`
  width: 51px;
`

export default ProductCard
